/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  /* Modal Content */
  .modal-content {
    background: white;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  /* Modal Header */
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .modal-header h2 {
    margin: 0;
    font-size: 24px;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  /* Modal Body */
  .modal-body {
    max-height: 400px; /* Adjust as needed */
    overflow-y: auto;
  }
  
  .input-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .input-group label {
    flex: 1;
    margin-right: 10px;
    font-weight: bold;
  }
  
  .input-group input {
    flex: 2;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  /* Modal Footer */
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .generate-button,
  .cancel-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .generate-button {
    background-color: #007bff;
    color: white;
  }
  
  .generate-button:hover {
    background-color: #0056b3;
  }
  
  .cancel-button {
    background-color: #ccc;
  }
  
  .cancel-button:hover {
    background-color: #999;
  }
  