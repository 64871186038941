@import url('https://fonts.googleapis.com/css2?family=Share:ital,wght@0,400;0,700;1,400;1,700&display=swap');
.barcode-grid{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0;
    /* gap: 20px;  */

}

  .print-button {
    margin-top: 20px; /* Adjust margin as needed */
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .print-button:hover {
    background-color: #0056b3;
    text-align: center;
  }

@media print {
  /* Prevents page break inside elements */
  .barcode-wrapper, .barcode-grid, .barcode-display-page {
    page-break-inside: avoid;
    break-inside: avoid; /* for newer browsers */
  }

  /* Adjust margins and padding to fit content within pages */
  body, .barcode-display-page {
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
  }

  /* Adjust grid to avoid content spilling over to a new page */

  /* Prevent printing of blank space on the last page */
  .barcode-display-page {
    height: auto;
  }

  /* Hide the print button during print */
  .print-button {
    display: none;
    margin: 0;
  }
}

.font_share{
  font-family: "Share", sans-serif !important;
  
}
  
 